
.App {
  text-align: center;
  margin: 0 auto;
  /* background-color: #F4F5FA; */
  overflow-x: hidden;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  background-color: transparent;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 0.1875rem;
  z-index: 1900 !important;
}

::-webkit-scrollbar-thumb {
  background: red;
}

::-webkit-scrollbar-track {
  background: #E4EFEF;
  border-radius: 2.5px;

}

@media (max-width: 992px) {
  ::-webkit-scrollbar {
  display: none;
  }
}


